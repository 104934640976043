import React, { Component } from 'react';
import MyGroceryElement from '../components/myGroceryList/MyGroceryElement';
import PageTitle from 'components/common/PageTitle';
import { Scrollbars } from "react-custom-scrollbars";
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Container
} from 'reactstrap';

import axios from 'axios';
import '../assets/css/custom.css';

import { idCampaignName, myGroceryListName } from '../helper/constant';
import { serverResponse } from '../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../settings/gaConfig';

import { connect } from 'react-redux';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

class MyGroceryList extends Component {

    state = {
        list: []
    }

    componentWillUnmount() {
        document.body.style.overflowY = "hidden";
    }

    componentDidMount() {
        var tmpProducts = this.props.groceryList.list;

        tmpProducts.forEach((element) => {
            element.image_file = element.images[0] ? element.images[0].image_file : "";
        })

        this.setState({
            list: tmpProducts
        });

        document.body.style.overflowY = "auto";

        if (serverResponse.config.ga_active) {
            ReactGA.initialize(serverResponse.config.ga_tracking_id);
            ReactGA.pageview("/" + serverResponse.config.release_id + "/stampa-lista-spesa");
        }


        // domtoimage.toBlob(document.getElementById('aho'))
        //     .then(function (blob) {
        //         saveAs(blob, 'my-node.png');
        //     });

        // setTimeout(() => {
        //     domtoimage.toBlob(document.getElementById('aho'))
        //         .then(function (blob) {
        //             saveAs(blob, 'la-mia-lista-della-spesa.png');
        //         });
        // }, 5000);

        // var id = this.props.match.params.id;

        // //axios per il caricamento della lista
        // axios({
        //     method: 'get',
        //     url: 'https://www.wipdev.it/listaspesaapi/leaflet/v1/api/read?id=' + id,
        //     headers: {
        //     },
        // })
        //     .then(res => {
        //         let tmpProducts = res.data;
        //         tmpProducts.sort((a, b) => {
        //             const [aDone, bDone] = [a.done, b.done];
        //             if (aDone === bDone) {
        //                 const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
        //                 if (aID === -1) return +1
        //                 if (bID === -1) return -1
        //                 return aID - bID
        //             };
        //             if (aDone === true) return +1;
        //             if (bDone === true) return -1;
        //             return 1;
        //         });

        //         this.setState({
        //             list: tmpProducts
        //         });
        //     }).catch(error => {
        //         console.log(error);
        //     });
    }

    checkProduct = (e) => {

        //TODO
        //axios per l'incremento del counter 'done' del prodotto
        /*axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {

            }).catch(error => {
                //console.log(error);
            });*/
        let tmpProducts = this.state.list;
        tmpProducts[e.target.id].done = e.target.checked;
        tmpProducts.sort((a, b) => {
            const [aDone, bDone] = [a.done, b.done];
            if (aDone === bDone) {
                const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                if (aID === -1) return +1
                if (bID === -1) return -1
                return aID - bID
            };
            if (aDone === true) return +1;
            if (bDone === true) return -1;
            return 1;
        });
        this.setState({
            list: tmpProducts
        });

    }

    sortItems = (tmpProducts, e) => {
        tmpProducts[e.target.id].done = e.target.checked;
        tmpProducts.sort((a, b) => {
            const [aDone, bDone] = [a.done, b.done];
            if (aDone === bDone) {
                const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)]
                if (aID === -1) return +1
                if (bID === -1) return -1
                return aID - bID
            };
            if (aDone === true) return +1;
            if (bDone === true) return -1;
            return 1;
        });
        return tmpProducts;
    }

    backToLeaflet = () => {
        localStorage.setItem(myGroceryListName + serverResponse.leaflet.code, JSON.stringify(this.state.list));
        localStorage.setItem(idCampaignName, (this.state.list ? this.state.list[0].id_campagna : ''));
        window.location = "/leaflet";
    }

    saveAsImage = () => {
        document.getElementById('print-label').innerHTML = "...";
        domtoimage.toBlob(document.getElementById('print-list'))
            .then(function (blob) {
                saveAs(blob, 'la-mia-lista-della-spesa.png');
                document.getElementById('print-label').innerHTML = "SALVA COME IMMAGINE";
            });
    }

    render() {
        let tot = 0;
        const items = this.state.list;
        items.map((element, key) => {
            tot += parseFloat(element.currentPrice);
            element.index = key;
            element.images = [];
            element.images.push({ "image_file": element.image_file });
            return null;
        });
        tot = tot.toFixed(2);

        return (
            <div>
                <Container>
                    <Row style={{ marginTop: "10px" }}>
                        {/* className="my-grocery-card" */}
                        <Col lg="6" sm="6" md={{ size: 6 }}>
                            <a style={{ textDecoration: "none" }} href={process.env.PUBLIC_URL + "/"}>
                                <Row className="custom-grocery-back-btn btn-c">
                                    <span style={{ textAlign: "center", width: "100%" }}>TORNA AL VOLANTINO</span>
                                </Row>
                            </a>
                        </Col>
                        <Col lg="6" sm="6" md={{ size: 6 }}>
                            <div style={{ textDecoration: "none" }} onClick={this.saveAsImage}>
                                <Row className="custom-grocery-back-btn btn-c">
                                    <span id="print-label" style={{ textAlign: "center", width: "100%" }}>SALVA COME IMMAGINE</span>
                                    </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div id="print-list" className="custom-container">
                    <MetaTags>
                        <title>La mia lista della spesa</title>
                        <meta property="og:url" content={serverResponse.config.serverPath + "/myGroceryList"} />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content="Lista della spesa | Volantino Interattivo" />
                        <meta property="og:description" content="" />
                        <meta property="og:image" content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />
                    </MetaTags>

                    <PageTitle title="myGroceryList" className="ml-15" />


                    <Row style={{marginBottom: "20px"}}>
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            {/* <Scrollbars
                                autoHide
                                ref="scrollbars"
                                renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                                className="custom-my-grocery-scrollbar-2">

                                {this.state.list.map((element, i) => <MyGroceryElement info={element} key={i} checkProduct={this.checkProduct} />)}

                                <Col sm="12" md={{ size: 6, offset: 3 }} className="my-grocery-card">
                                    <Row className="custom-grocery-back-btn" style={{ cursor: "auto", marginBottom: "0" }}>
                                        <span style={{ textAlign: "center", width: "100%" }}>Totale: € {tot}</span>
                                    </Row>
                                </Col>
                            </Scrollbars> */}
                            {this.state.list.map((element, i) => <MyGroceryElement info={element} key={i} checkProduct={this.checkProduct} />)}

                                <Col sm="12" md={{ size: 6, offset: 3 }} className="my-grocery-card">
                                    <Row className="custom-grocery-back-btn" style={{ cursor: "auto", marginBottom: "0" }}>
                                        <span style={{ textAlign: "center", width: "100%" }}>Totale: € {tot}</span>
                                    </Row>
                                </Col>
                            {/* {this.state.list.map((element, i) => <MyGroceryElement info={element} key={i} checkProduct={this.checkProduct} />)} */}
                        </Col>
                    </Row>
                    {/* <Row style={{
                    position: "fixed",
                    bottom: "0",
                    right: "0",
                    left: "0",
                    marginLeft: "unset !important",
                    marginRight: "unset !important"
                }}>
                    <Col sm="12" md={{ size: 6, offset: 3 }} className="my-grocery-card">
                        <Row className="custom-grocery-back-btn" style={{ cursor: "auto", marginBottom: "0" }}>
                            <span style={{ textAlign: "center", width: "100%" }}>Totale: € {tot}</span>
                        </Row>
                    </Col>
                </Row> */}
                </div>
            </div>
        );
    }

}

// export default MyGroceryList;

const mapStateToProps = state => {

    return {
        groceryList: state.groceryList,
    };

}

export default connect(mapStateToProps, null)(MyGroceryList);