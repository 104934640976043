import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus,
    faMinusCircle,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/grocery-product.css';
import {
    CustomInput,
    Tooltip
} from 'reactstrap';

import { connect } from 'react-redux';
import actions from '../../redux/groceryList/action';
import RemoveProd from '../../assets/images/icons/remove_prod.svg';

import {strings} from '../../util/data/strings';

class GroceryProduct extends Component {

    state = {
        deleteTooltipOpen: false,
    }

    incrementQty = () => {
        let qty = this.props.quantity + (this.props.quantity_step ? this.props.quantity_step : 1);
        this.props.changeQuantity({
            quantity: qty,
            item: this.props,
        });
    }

    decrementQty = () => {
        let qty = this.props.quantity;
        if (this.props.quantity_step) {
            if (qty > this.props.quantity_step) {
                qty -= this.props.quantity_step;
            }
        } else if (qty > 1) {
            qty--;
        }

        this.props.changeQuantity({
            quantity: qty,
            item: this.props,
        });
    }

    checkProduct = () => {
        this.props.checkProduct({
            item: this.props,
        })
    }

    deleteProduct = () => {
        this.props.deleteProduct({
            item: this.props
        })
    }

    toggleDeleteTooltip = () => {
        this.setState({ deleteTooltipOpen: !this.state.deleteTooltipOpen });
    }

    render() {
        const {
            title,
            price,
            variety,
            quantity,
            grocery_label,
            done,
            images,
            id
        } = this.props

        const varieryLabel = variety ? <span><br />- {variety}</span> : null;

        return (
            <ul className="list-inline pa-10 ma-0 ul-bottom-border flex ul-grocery" style={{ alignItems: 'center' }}>
                <li className="notification-popover-profile" >
                    <CustomInput type="checkbox" id={"checkbox-" + this.props.id} onChange={() => this.checkProduct()} checked={done} />
                </li>
                <li className="list-inline-item pa-5 mobile-grocery-product-container" style={{alignItems: "center"}}>
                    <img src={images ? (process.env.PUBLIC_URL + images[0].image_file) : ""} alt="img" style={{ width: "15%", height: "15%", marginRight: "5px" }} />
                    <h5 className="reduceMobile">{title} {varieryLabel} <br /> {price} €</h5>
                    <div className="grocery-product-quantity">
                        <FontAwesomeIcon icon={faMinusCircle} className="change-product-quantity-icon" style={{ marginRight: "10px"}} onClick={() => this.decrementQty()} />
                        <h6>{quantity}</h6>
                        <h6 style={{ marginLeft: "5px" }}>{(grocery_label) ? grocery_label : "pz."}</h6>
                        <FontAwesomeIcon icon={faPlusCircle} className="change-product-quantity-icon" style={{ marginLeft: "10px"}} onClick={() => this.incrementQty()} />

                        {/*<FontAwesomeIcon icon={faTrashAlt} style={{ color: "#ff0000", marginLeft: "20px" }} onClick={() => this.deleteProduct()} />*/}
                        <img id={"btnDelete_"+id} src={RemoveProd} style={{ color: "#00ff00", marginLeft: "20px", width: "30px", cursor: "pointer" }} onClick={() => this.deleteProduct()} alt="img" />
                        <Tooltip placement="top" isOpen={this.state.deleteTooltipOpen} target={"btnDelete_"+id} toggle={this.toggleDeleteTooltip}>
                            {strings.deleteBtnTooltip}
                        </Tooltip>
                    </div>
                </li>
            </ul>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeQuantity: (newQuantity) => {
            dispatch(actions.changeQuantity(newQuantity));
        },
        checkProduct: (check) => {
            dispatch(actions.checkProduct(check));
        },
        deleteProduct: (deleted) => {
            dispatch(actions.deleteProduct(deleted));
        }
    }
}

export default connect(null, mapDispatchToProps)(GroceryProduct);