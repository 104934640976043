import React, { Component } from 'react';
import { isMobile } from "react-device-detect";

class CustomIndex extends Component {

    render() {

        const containerClass = {
            position: "relative",
            display: "inline-block",
            // width: "100%"
        }

        let align_page = 'right';

        if (isMobile) {
            align_page = 'center';
        }

        const wrapperClass = {
            width: "100%",
            textAlign: align_page,
        }

        const imgClass = {
            maxHeight: "calc(100vh - 100px)",
            // minHeight: "500px",
            maxWidth: "100%"
        }

        const imgMobileClass = {
            width: "100%"
        }

        const listLink = this.props.index.links.map((element, key) => {
            if (element.type === undefined)
                return <CustomLink key={key} page={element.page} blueprint={element.blueprint} goToPage={this.props.goToPage} />
            else {
                return <CustomUrl key={key} page={element.page} blueprint={element.blueprint} url={element.url} target={element.target} />
            }
        });

        return (
            <div style={wrapperClass}>
                <div style={containerClass}>
                    <img className="checkMobileClassImg" src={process.env.PUBLIC_URL + this.props.index.image_file} style={isMobile ? imgMobileClass : imgClass} alt="" />

                    {listLink}

                </div>
            </div>
        );
    }
}

export default CustomIndex;

const CustomLink = props => {

    const styleProduct = {
        position: "absolute",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        top: props.blueprint.top + "%",
        left: props.blueprint.left + "%",
        width: props.blueprint.width + "%",
        height: props.blueprint.height + "%",
    }

    return (
        <div className="customLink" style={styleProduct} onClick={() => props.goToPage(props.page)}>
        </div>
    );


}

const CustomUrl = props => {

    const styleProduct = {
        position: "absolute",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        top: props.blueprint.top + "%",
        left: props.blueprint.left + "%",
        width: props.blueprint.width + "%",
        height: props.blueprint.height + "%",
    }

    return (
        <div
            className="customLink"
            style={styleProduct}
            onClick={
                () => {
                    if (props.target === undefined)
                        window.open(props.url, '_self');
                    else
                        window.open(props.url, '_blank');
                }
            }
        >
        </div>
    );


}