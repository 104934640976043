const leaflets = {
    "megacolor": {
        "config": {
            "primary-color": "rgb(88, 160, 199)",
            "secondary-color": "rgb(199, 9, 32)",
            "tertiary-color": "rgb(99, 44, 56)",
            "hover-color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": require("assets/images/logo_trice.png"),
            "serverPath": "megacolor.volantinointerattivo.net/",
            "pdfPath": "/media/pdf/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino di Megacolor!",
            "shareFlyerURL": "https://megacolor.volantinointerattivo.net",
            "shareProductSentence": "Guarda questa fantastica offerta!",
            "ogTitleMeta": "Volantino Interattivo Megacolor",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://megacolor.volantinointerattivo.net/media/images/megacolor_fb.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": true,
            "ga_tracking_id": "UA-152069090-26",
            "release_id": "1",
            "send_grocery_list_to_market": true,
            "grocery_list_min_value": 0.1,
            "client_id": 20,
            "signboard_id": 37,
            "has_pages": true,
            "hide_grocery_list": false,
            "hide_searchbar": false,
            "line_through": false,
            "category_banner": "",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
            "type": "leaflet"

        },
        "leaflet": {
            "name": "Megacolor_demo",
            "code": "456",
            "id_campaign": "456",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/indice_2Elm4r0.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 32.16336005242023,
                            "left": 10.028150991682663,
                            "width": 79.49130944764342,
                            "height": 6.370656734570925
                        }
                    },
                    {
                        "page": 4,
                        "blueprint": {
                            "top": 40.608657557218706,
                            "left": 9.756717850287908,
                            "width": 79.49130944764342,
                            "height": 6.370656734570925
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 49.858392986607285,
                            "left": 9.756717850287908,
                            "width": 79.49130944764342,
                            "height": 6.169330469523765
                        }
                    },
                    {
                        "page": 8,
                        "blueprint": {
                            "top": 59.22669978370622,
                            "left": 9.756717850287908,
                            "width": 79.49130944764342,
                            "height": 6.178718154916415
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Giardino e Giardinaggio",
                    "subcategories": [
                        "Arredamento",
                        "Barbecue e picnic",
                        "Altro"
                    ]
                },
                {
                    "category_name": "Sport e Tempo Libero",
                    "subcategories": [
                        "Altro"
                    ]
                },
                {
                    "category_name": "Casa e Cucina",
                    "subcategories": [
                        "Arredamento",
                        "Altro"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/page_1_jdEf4cK.jpg",
                    "products": [
                        {
                            "item_id": "cstm51034396",
                            "field1": "Salottino Lisbona",
                            "field2": "colore tortora",
                            "field3": "",
                            "field4": "",
                            "description": "Salottino Lisbona colore tortora",
                            "grammage": null,
                            "price": "99.00",
                            "price_label": "€ 99,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 32.61121673828075,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 33.050119306547884
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_HqVcBLN_NEMYP6y.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Composto da:",
                                    "data": {
                                        "specifiche": "Divanetto 114x72x78 cm, 2 poltrone 58x72x78 cm, Tavolino 83x45x37 cm",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/FLAYnttRIy/images/cropped_image_sC86AL1.png"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm96528387",
                            "field1": "Piscina fuori terra",
                            "field2": "con telaio in acciaio 400x200x h 100 cm",
                            "field3": "con pompa a filtro e scaletta",
                            "field4": "",
                            "description": "Piscina fuori terra con telaio in acciaio 400x200x h 100 cm con pompa a filtro e scaletta",
                            "grammage": null,
                            "price": "309.00",
                            "price_label": "€ 309,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 65.75261149195956,
                                "left": 0.0,
                                "width": 51.051556835146094,
                                "height": 23.965026536234387
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_QtcsUyn_hSEVpMY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "TRATTAMENTO DELL’ ACQUA - Misurazione del ph",
                                        "testo": "La prima cosa da fare per effettuare un efficacie trattamento dell’acqua è misurare ed eventualmente regolare, i valori del pH (Ricorda che da questa operazione dipendono tutti i trattamenti successivi, se il valore del pH è sbagliato gli altri interventi di disinfezione non funzioneranno).\nLa scala di misurazione del pH dell’acqua va da 0 a 14, il valore corretto in piscina deve essere compreso tra 7.2 e 7.6.\nUn pH inferiore a 7.2 (eccessivamente acido), provocherebbe irritazione alla pelle e agli occhi e danneggerebbe lentamente la struttura della piscina.\nUn pH superiore a 7.6 (molto alcalino), impedirebbe al cloro e alle altre sostanze chimiche, di svolgere la loro funzione disinfettante.\nUna volta misurato il valore del pH presente in piscina, bisogna procedere alla sua correzione con appositi prodotti chimici: pH+ e pH-.\nN.B. Quando utilizzi questi prodotti, ricorda di maneggiarli con attenzione ed evita il contatto diretto con pelle. Per un corretto dosaggio, segui attentamente le istruzioni riportate sulla confezione.\n(Fonte: blog.bricobravo.com)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/FLAYnttRIy/images/misuratori-ph-manutenzione-piscine.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm32037902",
                            "field1": "Ombrellone 3mt",
                            "field2": "in poliestere ",
                            "field3": "con struttura in alluminio",
                            "field4": "",
                            "description": "Ombrellone 3mt in poliestere  con struttura in alluminio",
                            "grammage": null,
                            "price": "39.90",
                            "price_label": "€ 39,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 65.75261149195957,
                                "left": 51.051556835146094,
                                "width": 48.94844316485392,
                                "height": 23.96502653623439
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_9C0QJZ2_rA25JYI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/page_2_fkBqjP4.jpg",
                    "products": [
                        {
                            "item_id": "cstm28764926",
                            "field1": "Set da giardino",
                            "field2": "in polyrattan",
                            "field3": "con struttura in ferro",
                            "field4": "",
                            "description": "Set da giardino in polyrattan con struttura in ferro",
                            "grammage": null,
                            "price": "499.00",
                            "price_label": "€ 499.00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 6.90978886756238,
                                "left": 0.0,
                                "width": 49.230939432714855,
                                "height": 22.392834293026233
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_nV0e2zu_foO1Tnn.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Composto da:",
                                    "data": {
                                        "specifiche": "Divano 2 braccioli 176x67x73,5 cm, Divano 1 bracciolo 165x67x73,5 cm, 2 pouf 40x40x36 cm, Cuscini in poliestere, Tavolo con top in vetro temperato nero da 5 mm",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/FLAYnttRIy/images/cropped_image_julqC1i_EJpGelI.png"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm62961432",
                            "field1": "Salottino ",
                            "field2": "in polyrattan marrone",
                            "field3": "con struttura in ferro",
                            "field4": "",
                            "description": "Salottino  in polyrattan marrone con struttura in ferro",
                            "grammage": null,
                            "price": "349.00",
                            "price_label": "€ 349,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 6.90978886756238,
                                "left": 49.23093943271486,
                                "width": 50.76906056728514,
                                "height": 22.392834293026233
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_ShVYIHB_z81CLCk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "PULIZIA",
                                    "data": {
                                        "titolo": "PULIZIA",
                                        "testo": "Se si trovano all'esterno, probabilmente sono più soggetti all'usura rispetto ai mobili che si trovano all'interno, soprattutto a causa dell'esposizione diretta alla luce solare. Questo si nota facilmente perché il colore del materiale si schiarisce o si scurisce a seconda dei casi. Per questo è necessario pulirli regolarmente per mantenere il loro aspetto originario. La pulizia dei mobili in rattan va effettuata con alcune gocce di detersivo diluito in acqua e passando un panno sulla superficie. Per raggiungere tutti gli angoli è possibile utilizzare un vecchio spazzolino da denti. Una volta terminata la pulizia, occorre lasciar asciugare il mobile. Se si ha intenzione di dipingere o laccare il rattan, al momento di pulirlo occorre sostituire il detersivo con ammoniaca. (Fonte: progetti.habitatissimo.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/FLAYnttRIy/images/cropped_image_oogm2MN_tFaqQcg.png"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm19411551",
                            "field1": "Tavolo + 4 sedie",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Tavolo + 4 sedie",
                            "grammage": null,
                            "price": "379.00",
                            "price_label": "€ 379,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 29.47621993725708,
                                "left": 2.247067605033057,
                                "width": 63.11484325015995,
                                "height": 44.37449550616402
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_aaxXN4j_8UE33gP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm70352547",
                            "field1": "Salottino Lisbona",
                            "field2": "colore tortora",
                            "field3": "",
                            "field4": "",
                            "description": "Salottino Lisbona colore tortora",
                            "grammage": null,
                            "price": "99.00",
                            "price_label": "€ 99,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 29.47621993725708,
                                "left": 65.36191085519299,
                                "width": 34.638089144807005,
                                "height": 44.37449550616402
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_T51bc41_exjIteB.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm63183628",
                            "field1": "Tavolo da pranzo",
                            "field2": "allungabile",
                            "field3": "in legno di eucalypto",
                            "field4": "",
                            "description": "Tavolo da pranzo allungabile in legno di eucalypto",
                            "grammage": null,
                            "price": "184.90",
                            "price_label": "€ 184,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 74.38984757641255,
                                "left": 0.0,
                                "width": 69.83391981232674,
                                "height": 22.42951789899831
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_toK5yXs_kDlFuih.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm41952789",
                            "field1": "Sedia pieghevole",
                            "field2": "in legno di eucalypto",
                            "field3": "",
                            "field4": "",
                            "description": "Sedia pieghevole in legno di eucalypto",
                            "grammage": null,
                            "price": "32.90",
                            "price_label": "€ 32,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 74.38984757641255,
                                "left": 58.252772446150566,
                                "width": 41.74722755384943,
                                "height": 22.42951789899831
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_ihG8kmq_SIhqmMw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/page_3_Ymgd3xp.jpg",
                    "products": [
                        {
                            "item_id": "cstm18928175",
                            "field1": "Coppia di sedie",
                            "field2": "+ tavolo da esterno",
                            "field3": "con motivo a mosaico",
                            "field4": "",
                            "description": "Coppia di sedie + tavolo da esterno con motivo a mosaico",
                            "grammage": null,
                            "price": "99.00",
                            "price_label": "€ 99,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 6.8458093410108765,
                                "left": 0.0,
                                "width": 65.60740563019834,
                                "height": 22.520793346129242
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_PDiDv5D_qQyLSGH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm54687189",
                            "field1": "Poltrona reclinabile",
                            "field2": "3 posizioni",
                            "field3": "",
                            "field4": "",
                            "description": "Poltrona reclinabile 3 posizioni",
                            "grammage": null,
                            "price": "54.90",
                            "price_label": "€ 54,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 6.8458093410108765,
                                "left": 65.60740563019834,
                                "width": 34.392594369801664,
                                "height": 22.520793346129242
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_f81S2EQ_8oYEHz0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm39187793",
                            "field1": "Poltroncina relax pieghevole",
                            "field2": "82x73x h 80 cm",
                            "field3": "",
                            "field4": "",
                            "description": "Poltroncina relax pieghevole 82x73x h 80 cm",
                            "grammage": null,
                            "price": "49.90",
                            "price_label": "€ 49,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 29.558541266794627,
                                "left": 0.09047771379825122,
                                "width": 34.392594369801664,
                                "height": 22.520793346129242
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_X8hKWUk_okndcfo.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm88426747",
                            "field1": "Poltrona pensile",
                            "field2": "in rattan sintetico intrecciato",
                            "field3": "",
                            "field4": "",
                            "description": "Poltrona pensile in rattan sintetico intrecciato",
                            "grammage": null,
                            "price": "129.00",
                            "price_label": "€ 129,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 52.335252719129876,
                                "left": 0.0,
                                "width": 34.392594369801664,
                                "height": 22.520793346129242
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_ceVEVZR_6649vvN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm40403720",
                            "field1": "Amaca prendisole",
                            "field2": "230x98x h 82 cm",
                            "field3": "",
                            "field4": "",
                            "description": "Amaca prendisole 230x98x h 82 cm",
                            "grammage": null,
                            "price": "79.90",
                            "price_label": "€ 79,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 29.49456174024312,
                                "left": 65.60740563019834,
                                "width": 34.392594369801664,
                                "height": 22.520793346129242
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_Kav3kF0_ZPvH9u6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm27921874",
                            "field1": "Dondolo",
                            "field2": "2 posti",
                            "field3": "con cuscini e tetto parasole",
                            "field4": "",
                            "description": "Dondolo 2 posti con cuscini e tetto parasole",
                            "grammage": null,
                            "price": "119.00",
                            "price_label": "€ 119,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 52.143314139475365,
                                "left": 65.60740563019834,
                                "width": 34.392594369801664,
                                "height": 22.520793346129242
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_PrwiYJx_lyNhxAG.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm91454150",
                            "field1": "BBQ a carbonella",
                            "field2": "piano 58x42,5 cm",
                            "field3": "",
                            "field4": "",
                            "description": "BBQ a carbonella piano 58x42,5 cm",
                            "grammage": null,
                            "price": "139.00",
                            "price_label": "€ 139,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Barbecue e picnic",
                            "blueprint": {
                                "top": 29.558541266794627,
                                "left": 34.57354979739816,
                                "width": 30.86396353166987,
                                "height": 44.40179142674344
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_sjUhxnb_kqpwmFP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "COME PULIRE IL BARBECUE - IL DETERGENTE MAGICO E FATTO IN CASA",
                                        "testo": "Ingredienti: un biccchiere di alcool etilico rosa (non l’acool bianco da pulizie, che è tossico), un cucchiaio da cucina di percarbonato di sodio puro o, in alternativa, di sbiancante per bucato, mezzo cucchiaio di detersivo liquido per piatti, acqua, q.b. per riempire un flacone riciclato, con spruzzatore, un flacone con spruzzatore e una piccola caraffa. Dato che il percarbonato di sodio è poco solubile in acqua, si deve procedere sciogliendolo in acqua in una caraffa e aspettando di far depositare quello insoluto. A questo punto, si preleva solo il liquido ottenuto, senza il deposito. Si versa prima il bicchiere di alcool nel flacone, poi il detersivo per piatti ed infine la soluzione di acqua e percarbonato. Si termina, naturalmente, agitando il tutto. Questo sgrassatore alcalino si può benissimo spruzzare direttamente sulle griglie ancora calde, in questo modo, il grasso non si sarà solidificato e si eliminerà senza fatica. Non avendo ingredienti tossici, come quelli commerciali, anche l’eventuale riscaldamento, infatti, non comporterà la produzione di fumi nocivi. (Fonte: bbq4all.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/FLAYnttRIy/images/cropped_image_YXOLfUr_qxjQASC.png"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm57283768",
                            "field1": "Carbonella di legna",
                            "field2": "5 Kg",
                            "field3": "",
                            "field4": "",
                            "description": "Carbonella di legna 5 Kg",
                            "grammage": null,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Barbecue e picnic",
                            "blueprint": {
                                "top": 74.5361484325016,
                                "left": 0.0,
                                "width": 33.3973395180209,
                                "height": 22.072936660268713
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/1603_016037_carbone_01_pii1143_F8OiHqL.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_5q2by2y_z0RBzUy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm11345428",
                            "field1": "BBQ a gas ",
                            "field2": "piano 51x37 cm",
                            "field3": "a pietra lavica",
                            "field4": "",
                            "description": "BBQ a gas  piano 51x37 cm a pietra lavica",
                            "grammage": null,
                            "price": "129.00",
                            "price_label": "€ 129,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Barbecue e picnic",
                            "blueprint": {
                                "top": 74.15227127319258,
                                "left": 33.3973395180209,
                                "width": 32.31160695244189,
                                "height": 22.26487523992322
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_1eI4VOf_jD596iM.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm68019656",
                            "field1": "Bricchetti",
                            "field2": "di carbonella",
                            "field3": "8 Kg",
                            "field4": "",
                            "description": "Bricchetti di carbonella 8 Kg",
                            "grammage": null,
                            "price": "16.90",
                            "price_label": "€ 16,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Barbecue e picnic",
                            "blueprint": {
                                "top": 74.34420985284709,
                                "left": 65.42645020260183,
                                "width": 34.573549797398165,
                                "height": 22.32885476647473
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_7GFCcP8_4KMQ9fc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/page_4_tH3U3zG.jpg",
                    "products": [
                        {
                            "item_id": "cstm98378841",
                            "field1": "Gazebo 3x3mt",
                            "field2": "in alluminio",
                            "field3": "verniciato",
                            "field4": "",
                            "description": "Gazebo 3x3mt in alluminio verniciato",
                            "grammage": null,
                            "price": "299.00",
                            "price_label": "€ 299,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 7.261104164929228,
                                "left": 2.4127408753938324,
                                "width": 46.989703421858124,
                                "height": 21.995879319593715
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_n23847U_L9cULE5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm45979775",
                            "field1": "Ombrellone 3mt",
                            "field2": "con struttura a braccio",
                            "field3": "",
                            "field4": "",
                            "description": "Ombrellone 3mt con struttura a braccio",
                            "grammage": null,
                            "price": "59.90",
                            "price_label": "€ 59,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 7.261104164929228,
                                "left": 49.90509703561526,
                                "width": 50.09490296438474,
                                "height": 21.995879319593715
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_Z0ugNZK_hTcUL4a.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm18942200",
                            "field1": "Ombrellone 3x3 mt",
                            "field2": "a braccio con manovella",
                            "field3": "",
                            "field4": "",
                            "description": "Ombrellone 3x3 mt a braccio con manovella",
                            "grammage": null,
                            "price": "249.00",
                            "price_label": "€ 249,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 29.36958804383798,
                                "left": 2.2534937387744263,
                                "width": 63.063378957015,
                                "height": 44.45980414489962
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_3xtRCFS_I00O9z1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm64836767",
                            "field1": "Ombrellone 3 mt",
                            "field2": "in poliestere ",
                            "field3": "con struttura in alluminio",
                            "field4": "",
                            "description": "Ombrellone 3 mt in poliestere  con struttura in alluminio",
                            "grammage": null,
                            "price": "39.90",
                            "price_label": "€ 39,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 29.36958804383798,
                                "left": 65.31687269578944,
                                "width": 34.68312730421057,
                                "height": 23.275469850623786
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_Y19Ywjk_4I2CFo3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm80036198",
                            "field1": "Gazebo in ferro",
                            "field2": "completo di telo",
                            "field3": "",
                            "field4": "",
                            "description": "Gazebo in ferro completo di telo",
                            "grammage": null,
                            "price": "109.00",
                            "price_label": "€ 109,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 52.85832558630038,
                                "left": 65.71899709540864,
                                "width": 31.968795890263035,
                                "height": 20.94149204969311
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_dNIEBNA_aiXt74U.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm97826321",
                            "field1": "Tenda ",
                            "field2": "barra quadra 3x2 mt",
                            "field3": "",
                            "field4": "",
                            "description": "Tenda  barra quadra 3x2 mt",
                            "grammage": null,
                            "price": "109.00",
                            "price_label": "€ 109,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Rosso",
                                "Verde",
                                "Blu",
                                "Giallo"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 74.39810277947355,
                                "left": 2.010616475774627,
                                "width": 31.16455261334602,
                                "height": 22.221082580723177
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_kQSwVik_tD1uUqi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm97135214",
                            "field1": "Tenda",
                            "field2": "a caduta",
                            "field3": "2x2,5 mt",
                            "field4": "",
                            "description": "Tenda a caduta 2x2,5 mt",
                            "grammage": null,
                            "price": "28.90",
                            "price_label": "€ 28,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Verde",
                                "Giallo",
                                "Azzurro",
                                "Marrone"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 74.39810277947355,
                                "left": 33.175169089120644,
                                "width": 32.16984704542984,
                                "height": 22.221082580723177
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_fL6Th0C_9j0wie1.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm14860600",
                            "field1": "Tenda vela ",
                            "field2": "triangolare 3x3x3 mt",
                            "field3": "",
                            "field4": "",
                            "description": "Tenda vela  triangolare 3x3x3 mt",
                            "grammage": null,
                            "price": "14.90",
                            "price_label": "€ 14,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 74.39810277947355,
                                "left": 65.34501613455049,
                                "width": 32.14170912899018,
                                "height": 22.221082580723177
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_8DuPXrX_NkA5dy7.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/page_5_rLJJk9I.jpg",
                    "products": [
                        {
                            "item_id": "cstm07515493",
                            "field1": "Zanzariera",
                            "field2": "a rullo per finestra",
                            "field3": "100x170 cm",
                            "field4": "",
                            "description": "Zanzariera a rullo per finestra 100x170 cm",
                            "grammage": null,
                            "price": "29.90",
                            "price_label": "€ 29,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 7.290686527674131,
                                "left": 29.15393061524999,
                                "width": 36.062402697152535,
                                "height": 22.292169208002452
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_nsZGRA1_YJE853v.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm51568243",
                            "field1": "Zanzarirera",
                            "field2": "a rullo per porta finestra",
                            "field3": "160x250 cm",
                            "field4": "",
                            "description": "Zanzarirera a rullo per porta finestra 160x250 cm",
                            "grammage": null,
                            "price": "64.90",
                            "price_label": "€ 64,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Casa e Cucina",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 7.290686527674131,
                                "left": 65.21633331240253,
                                "width": 34.78366668759747,
                                "height": 22.292169208002452
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_jbTUIyS_K21IJV5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm10523661",
                            "field1": "Grriglia",
                            "field2": "rettangolare",
                            "field3": "",
                            "field4": "",
                            "description": "Grriglia rettangolare",
                            "grammage": null,
                            "price": "17.90",
                            "price_label": "€ 17,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 29.754615257980433,
                                "left": 1.6808769761384712,
                                "width": 31.86827307384033,
                                "height": 44.68500740602909
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_XSV2cWW_Y4QxQvw.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm05423475",
                            "field1": "Fioriera a vasca ",
                            "field2": "con grigliato",
                            "field3": "",
                            "field4": "",
                            "description": "Fioriera a vasca  con grigliato",
                            "grammage": null,
                            "price": "34.90",
                            "price_label": "€ 34,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 29.754615257980433,
                                "left": 33.549150049978806,
                                "width": 32.270375384173946,
                                "height": 44.68500740602909
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_3ueB0cQ_0qaMQvW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm26636991",
                            "field1": "Armadio",
                            "field2": "da giardino",
                            "field3": "85x45x177 cm",
                            "field4": "",
                            "description": "Armadio da giardino 85x45x177 cm",
                            "grammage": null,
                            "price": "119.00",
                            "price_label": "€ 119,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 29.754615257980433,
                                "left": 65.81952543415275,
                                "width": 31.566682535286624,
                                "height": 44.68500740602909
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_4VP4nyx_Gvskydj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm47188023",
                            "field1": "Recinzione",
                            "field2": "in pino impregnato",
                            "field3": "120x30 cm con paletti da 45 cm",
                            "field4": "",
                            "description": "Recinzione in pino impregnato 120x30 cm con paletti da 45 cm",
                            "grammage": null,
                            "price": "4.90",
                            "price_label": "€ 4,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 74.68246295609204,
                                "left": 0.0,
                                "width": 33.147025650359595,
                                "height": 21.86564163432603
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_RybvQi9_LgwhA0Q.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm42038407",
                            "field1": "Bordura per aiuole",
                            "field2": "200x5x25 cm",
                            "field3": "",
                            "field4": "",
                            "description": "Bordura per aiuole 200x5x25 cm",
                            "grammage": null,
                            "price": "4.99",
                            "price_label": "€ 4,99",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 74.68246295609204,
                                "left": 34.15233664940761,
                                "width": 31.39374168495248,
                                "height": 21.86564163432603
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_RoUTANz_IGrVtsJ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm66937809",
                            "field1": "Prato artificiale",
                            "field2": "in rotolo 1x5 mt",
                            "field3": "",
                            "field4": "",
                            "description": "Prato artificiale in rotolo 1x5 mt",
                            "grammage": null,
                            "price": "19.90",
                            "price_label": "€ 19,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Giardino e Giardinaggio",
                            "subcategory": "Arredamento",
                            "blueprint": {
                                "top": 74.82464011565098,
                                "left": 65.9482027339793,
                                "width": 31.337476896715966,
                                "height": 21.723464474767088
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/products/cropped_image_8giFjHU_PCih8Cj.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/page_6_ecIGQWv.jpg",
                    "products": []
                },
                {
                    "number": 7,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/page_7_eahFO0l.jpg",
                    "products": []
                },
                {
                    "number": 8,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/page_8_nmwgQ4b.jpg",
                    "products": []
                },
                {
                    "number": 9,
                    "image_file": "https://interattivo.s3.amazonaws.com/FLAYnttRIy/pages/fine_bw1hDBg.png",
                    "products": []
                }
            ]
        }
    }

}

const lefletSwitch = () => {
    return leaflets.megacolor;
}

export const serverResponse = lefletSwitch(); 